import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import fetch from 'isomorphic-fetch';

const graphqlUri =
  process.env.GATSBY_GRAPHQL_MODE === 'production'
    ? process.env.GATSBY_GRAPHQL_URI_PRODUCTION
    : process.env.GATSBY_GRAPHQL_URI_STAGING;

const isServer = typeof window === 'undefined';

const link = createUploadLink({
  uri: graphqlUri,
  fetch,
  fetchOptions: {
    'Access-Control-Allow-Origin': '*',
  },
  credentials: 'include',
  headers: {
    authorization: '',
  },
});

const client = new ApolloClient({
  ssrMode: isServer,
  link,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          getSitemapRegion: {
            merge: true,
            keyArgs: ['regionSlug', 'keywordUri'],
            read(existing) {
              return existing;
            }
          }
        }
      }
    }
  }),
  defaultOptions: {
    query: {
      fetchPolicy: 'cache-first',
    },
    watchQuery: {
      fetchPolicy: 'cache-first',
      nextFetchPolicy: 'cache-first',
    },
  },
});

export default client;
