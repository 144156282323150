// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-your-bill-tsx": () => import("./../../../src/pages/about-your-bill.tsx" /* webpackChunkName: "component---src-pages-about-your-bill-tsx" */),
  "component---src-pages-bill-type-tsx": () => import("./../../../src/pages/bill-type.tsx" /* webpackChunkName: "component---src-pages-bill-type-tsx" */),
  "component---src-pages-block-rates-tsx": () => import("./../../../src/pages/block-rates.tsx" /* webpackChunkName: "component---src-pages-block-rates-tsx" */),
  "component---src-pages-business-tsx": () => import("./../../../src/pages/business.tsx" /* webpackChunkName: "component---src-pages-business-tsx" */),
  "component---src-pages-compare-tsx": () => import("./../../../src/pages/compare.tsx" /* webpackChunkName: "component---src-pages-compare-tsx" */),
  "component---src-pages-controlled-load-details-tsx": () => import("./../../../src/pages/controlled-load-details.tsx" /* webpackChunkName: "component---src-pages-controlled-load-details-tsx" */),
  "component---src-pages-controlled-load-tsx": () => import("./../../../src/pages/controlled-load.tsx" /* webpackChunkName: "component---src-pages-controlled-load-tsx" */),
  "component---src-pages-distributor-tsx": () => import("./../../../src/pages/distributor.tsx" /* webpackChunkName: "component---src-pages-distributor-tsx" */),
  "component---src-pages-household-tsx": () => import("./../../../src/pages/household.tsx" /* webpackChunkName: "component---src-pages-household-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-life-saving-tsx": () => import("./../../../src/pages/life-saving.tsx" /* webpackChunkName: "component---src-pages-life-saving-tsx" */),
  "component---src-pages-loading-tsx": () => import("./../../../src/pages/loading.tsx" /* webpackChunkName: "component---src-pages-loading-tsx" */),
  "component---src-pages-map-tsx": () => import("./../../../src/pages/map.tsx" /* webpackChunkName: "component---src-pages-map-tsx" */),
  "component---src-pages-onboarding-tsx": () => import("./../../../src/pages/onboarding.tsx" /* webpackChunkName: "component---src-pages-onboarding-tsx" */),
  "component---src-pages-peak-off-peak-tsx": () => import("./../../../src/pages/peak-off-peak.tsx" /* webpackChunkName: "component---src-pages-peak-off-peak-tsx" */),
  "component---src-pages-plan-info-tsx": () => import("./../../../src/pages/plan-info/[...].tsx" /* webpackChunkName: "component---src-pages-plan-info-tsx" */),
  "component---src-pages-results-index-tsx": () => import("./../../../src/pages/results/index.tsx" /* webpackChunkName: "component---src-pages-results-index-tsx" */),
  "component---src-pages-retailer-tsx": () => import("./../../../src/pages/retailer.tsx" /* webpackChunkName: "component---src-pages-retailer-tsx" */),
  "component---src-pages-sign-up-tsx": () => import("./../../../src/pages/sign-up.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */),
  "component---src-pages-single-rate-tsx": () => import("./../../../src/pages/single-rate.tsx" /* webpackChunkName: "component---src-pages-single-rate-tsx" */),
  "component---src-pages-solar-details-tsx": () => import("./../../../src/pages/solar-details.tsx" /* webpackChunkName: "component---src-pages-solar-details-tsx" */),
  "component---src-pages-solar-input-tsx": () => import("./../../../src/pages/solar-input.tsx" /* webpackChunkName: "component---src-pages-solar-input-tsx" */),
  "component---src-pages-solar-tsx": () => import("./../../../src/pages/solar.tsx" /* webpackChunkName: "component---src-pages-solar-tsx" */),
  "component---src-templates-results-region-results-tsx": () => import("./../../../src/templates/results/region-results.tsx" /* webpackChunkName: "component---src-templates-results-region-results-tsx" */)
}

